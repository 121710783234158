$main-color: #3b5998;
$base-bg-color: #fff;
$layer-bg-color: #f7f7f7;
$text: #333;

// Body
$body-bg: $base-bg-color;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: $text;

// Navbar
$navbar-default-bg: $layer-bg-color;
$navbar-default-text: $text;

// Buttons
$btn-default-color: $base-bg-color;
$btn-bg-color: $main-color;
$btn-hover: $text;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: $layer-bg-color;

// Footer
$footer-default-bg: $navbar-default-bg;
$footer-default-border: $navbar-default-border;
$footer-text-color: $text-color;
$footer-header-color: $btn-bg-color;
