// Variables
@import "backend_variables";
@import "variables";
@import "helpers";

.login, .register{
	margin-top: 10%;
}

.image-with-preview img{
	max-height: 300px;
	margin: 0 auto;
	border-bottom: 5px solid transparent;
}

.upload-rules{
	color: $brand_danger;
}

.unread{
	font-weight: bold;
	background-color: lighten($brand-primary, 40%) !important;
}

.modal-message{
	font-weight: normal;
}

.banner-img{
	position: relative;
	overflow: hidden;
	height: 75px;
	margin-left: 6px;
	margin-right: 6px;

	img{
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.banner-content{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.52);
		display: flex;
		flex-direction: column;
		justify-content: center;

		.content-text{
			color: #fff;
			width: 100%;
			display: block;
			text-align: center;

			h2{
				font-size: 24px;
				font-weight: bold;
				margin: 0;
			}
		}
	}
}

#btnExample{
	box-shadow: 0px 5px 15px rgba(0,0,0, .54);
	border: none;
}

#panelColor {
		display: block;
		border: solid 1px #000;
		min-height: 70px;
		min-width: auto;
		margin-left: 6px;
		margin-right: 6px;
    background: red; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(red, yellow); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(red, yellow); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(red, yellow); /* For Firefox 3.6 to 15 */
    background: linear-gradient(red, yellow); /* Standard syntax */
}

.fontExample{
	font-size: 16px;
}

body.dragging, body.dragging *{
  cursor: move !important;
}

.dragged{
  position: absolute;
  top: 0;
  opacity: .5;
  z-index: 2000;
}

ul.sortable{
  margin: 0 0 9px 0;
  min-height: 10px;
	
  li{
    display: block;
    margin: 5px;
    padding: 5px;
    border: 1px solid #CCC;
	}
  li.placeholder{
    position: relative;
    margin: 0;
    padding: 0;
    border: none;

    &:before{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      margin-top: -5px;
      left: -5px;
      top: -4px;
      border: 5px solid transparent;
			border-right: none;
		}
	}
}


/* Image Cropper CSS Properties */
.image-cropper {
  overflow: hidden;
  text-align: center;

	.cropit-preview {
		margin: 0 auto;	

		.cropit-preview-image-container{
			border: 1px solid #ccc;
		}

		.cropit-preview-background {
		  opacity: .2;
		}
		
		.caption {
			text-align: center;
			position: relative;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    top: 50%;
	    transform: perspective(1px) translateY(-50%);
	    font-weight: 700;
	    color: rgba(0,0,0,0.5);
		}

		img.cropit-preview-image {
			&:hover {
				cursor: move;
			}
		}

	}

  .controls-wrapper {
  	margin-top: 20px;
  	line-height: 28px;
  	display: inline-flex;
  	.rotation-btns {
  		a {
  			margin-left: 5px;
  			color: black;
  			&:hover {
					cursor: pointer;
				}
  		}
  	}
  	.slider-wrapper {
  		margin-left: 10px;
  		display: inline-flex;

  		input.cropit-image-zoom-input {
			  position: relative;
  			margin-left: 5px;
  			margin-right: 5px;
  			&:hover {
  				cursor: pointer;
  			}

			}
  		i {
  			line-height: 28px;
  		}
  	}
  }
}
.box-bordered {
    border: 1px solid #bbb;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 10px;
}